import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { IAllITagMasters } from '../../constants/tag-master.constants';

export enum FlavourLevels {
  NA = 'NA',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high'
}

export interface IEntityTpe {
  entityId: string;
  entityType: string;
  entityName: string;
}

export interface IFlavours {
  flavourId: string;
  flavourName: string;
  levels: Array<FlavourLevels>;
}

export interface ICustomizableOptions {
  canAddOn: boolean;
  canRemove: boolean;
  hasSubstitute: boolean;
  hasShape: boolean;
  hasLayer: boolean;
  hasTheme: boolean;
}

export interface ICreateOrUpdateSubCategoryPayload {
  subCategoryName: string;
  subCategoryS3FileName?: string;
  subCategoryOriginalFileName?: string;
  subCategoryDescription: string;
  categoryId: string;
  flavours: Array<IFlavours>;
  customizeOptions?: { [key: string]: boolean };
  isActive: boolean;
}

export interface ISubCategoryResponse {
  subCategoryId: string;
  subCategoryName: string;
  subCategoryDescription: string;
  subCategoryS3FileName: string;
  subCategoryOriginalFileName: string;
  categoryId: string;
  flavours: Array<IFlavours>;
  createdBy: IEntityTpe;
  isActive: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export interface ISubCategoryDetailsResponse {
  _id: string;
  subCategoryId: string;
  subCategoryName: string;
  subCategoryDescription: string;
  subCategoryS3FileName: string;
  subCategoryOriginalFileName: string;
  categoryId: string;
  categoryName: string;
  customizeOptions: { [key: string]: boolean },
  customizations?: string[], // used for internal purpose
  flavours: Array<IFlavours>;
  createdBy: IEntityTpe;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  updatedBy: IEntityTpe;
  subCategoryImageUrl: string;
}

export interface IAllSubCategoryResponse {
  subCategories: Array<ISubCategoryDetailsResponse>;
  subCatagoriesCount: number;
}
export interface IBrands {
  kitchenName?: string,
  kitchenId: string
}

export interface IUpdateSequencePayload {
  subCategoryIds: Array<string>;
}

@Injectable()
export class SubCategoryService extends RestApiService {
  protected kitchenApiUrl = environment.config['KITCHEN_SERVICE_URL'];

  protected adminApiUrl = environment.config['ADMIN_SERVICE_URL'];

  // private servicePath = `${this.kitchenApiUrl}/common/products-sub-category`;

  private servicePath = `${this.adminApiUrl}/products-sub-category`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async createSubCategory(addTo: string, payload: ICreateOrUpdateSubCategoryPayload)
    : Promise<{ data: ISubCategoryResponse }> {
    const apiPath = `${this.servicePath}?addTo=${addTo}`;
    return this.httpPost(apiPath, payload);
  }

  async updateSubCategory(subCategoryId: string, payload: ICreateOrUpdateSubCategoryPayload)
    : Promise<{ data: ISubCategoryResponse }> {
    const apiPath = `${this.servicePath}/${subCategoryId}`;
    return this.httpPatch(apiPath, payload);
  }

  async getSubCategoryById(subCategoryId: string)
    : Promise<{ data: ISubCategoryDetailsResponse }> {
    const apiPath = `${this.servicePath}/${subCategoryId}`;
    return this.httpGet(apiPath);
  }

  async getAllSubCategories(queryParams: Partial<any>)
    : Promise<{ data: IAllSubCategoryResponse }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async getAllTags(queryParams: Partial<any>): Promise<IAllITagMasters> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    searchParams.set('tagType', 'sellerProducts');
    const apiPath = `${this.adminApiUrl}/tags?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async deleteSubCategory(subCategoryId: string) {
    const apiPath = `${this.servicePath}/${subCategoryId}`;
    return this.httpDelete(apiPath);
  }

  async getAllBrands()
    : Promise<{ data: Array<IBrands> }> {
    // const searchParams = new URLSearchParams();
    // if (queryParams) {
    //   if (queryParams['limit']) {
    //     searchParams.set('limit', String(queryParams['limit']));
    //   }
    //   if ('skip' in queryParams) {
    //     searchParams.set('skip', String(queryParams['skip'] ?? 0));
    //   }
    //   if ('search' in queryParams) {
    //     searchParams.set('search', String(queryParams['search']));
    //   } else {
    //     searchParams.delete('search');
    //   }
    // }
    const apiPath = `${this.kitchenApiUrl}/kitchens/seller-profiles`;
    return this.httpGet(apiPath);
  }

  async getSubCategorySequenceList(categoryId: string)
    : Promise<{ data: Array<ISubCategoryDetailsResponse> }> {
    const apiPath = `${this.servicePath}/sequence/list?categoryId=${categoryId}`;
    return this.httpGet(apiPath);
  }

  async updateSubCategorySequence(categoryId: string, payload: IUpdateSequencePayload) {
    const apiPath = `${this.servicePath}/modify/sequence?categoryId=${categoryId}`;
    return this.httpPut(apiPath, payload);
  }
}
