import { Injectable } from '@angular/core';
import {
  BRAND_ADMIN_SIDEBAR_ITEMS,
  COOK_ADMIN_SIDEBAR_ITEMS,
  CUSTOMER_ADMIN_SIDEBAR_ITEMS,
  DRIVER_ADMIN_SIDEBAR_ITEMS,
} from '../../../constants/sidebar.constants';
import { TabTypes } from '../../../enums/tab.types';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  constructor() { }

  getSidebarItems(adminTabType: TabTypes) {
    if (adminTabType === TabTypes.COOK_ADMIN) {
      return COOK_ADMIN_SIDEBAR_ITEMS;
    }
    if (adminTabType === TabTypes.DRIVER_ADMIN) {
      return DRIVER_ADMIN_SIDEBAR_ITEMS;
    }
    if (adminTabType === TabTypes.CUSTOMER_ADMIN) {
      return CUSTOMER_ADMIN_SIDEBAR_ITEMS;
    }
    if (adminTabType === TabTypes.BRAND_ADMIN) {
      return BRAND_ADMIN_SIDEBAR_ITEMS;
    }
    return COOK_ADMIN_SIDEBAR_ITEMS; // TODO: handle this after all sidebar items are decided
  }
}
