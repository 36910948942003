import { Injectable } from '@angular/core';
import { removeEmptyValues } from '../../helpers/remove-empty-values.helpers';
import { RestApiService } from '../api.service';
import { environment } from '../../environments/environment';
import { HttpClientService } from '../http-client.service';

export interface IFileUrl {
  url: string;
  expiresIn?: number;
}

export interface IApprovalPayload {
  approvalStatus: string;
  remarks?: string;
}

export interface ICreatedBy {
  entityType: string;
  entityId: string;
  entityName: string;
}

export interface IAssets {
  s3FileName: string;
  originalFileName: string;
  fileUrl: IFileUrl;
  photoUrl: IFileUrl;
  contentType: string;
  fileType: string;
}

export interface IImageAssets {
  s3FileName: string;
  originalFileName: string;
}

export interface IPreScreening {
  basicInformation: {
    brandName: string;
    brandManagerOrPOC: {
      name: string;
      contactNumber: {
        primary: string;
        secondary: string;
      };
    },
    email: string;
    webSiteLink: string;
  },
  registeredOfficeAddress: {
    address: string;
    district: string;
    nearestLandmark: string;
    state: string;
    pincode: number;
  };
  fssaiCertificateDetails: {
    fssaiNumber: number;
    fssaiExpiryDate: string;
    certificateImage: IAssets;
  },
  termsAndConditionAgreement: {
    isAccepted: boolean;
  };
  status: {
    updatedAt: string;
    createdAt: string;
  };
}

export interface ITaxAndBankDetails {
  gstIn: string;
  panCard: string;
  udyogAadhaar: string;
  bankDetails: {
    accountHolderName: string;
    accountNumber: number;
    confirmAccountNumber: number;
    ifscCode: string;
    cancellationChequeOrBankPassBookImage: IAssets;
  },
  status: {
    isDraft: boolean;
    isSubmitted: boolean;
    updatedAt: string;
    createdAt: string;
    submittedAt: string;
  },
  isPanVerified: boolean;
  isGstInVerified: boolean;
  isUdyogAadhaarVerified: boolean;
}

export interface IVerification {
  documents: {
    productionUnit: Array<IAssets>;
    productsAndCatalogue: Array<IAssets>;
  },
  status: {
    updatedAt: string;
    createdAt: string;
  };
}

export interface IBrandSetup {
  brandProfile: {
    brandName: string;
    brandAssets: Array<IAssets>;
    badges: Array<string>;
    certificateAndLabTestReportAssets: Array<IAssets>;
  };
  status: {
    currentState: string;
  };
}

export interface IBrandProfileUpdate {
  brandProfile: {
    brandName: string;
    brandAssets: Array<IAssets>;
    badges: Array<string>;
    certificateAndLabTestReportAssets: Array<IAssets>;
  };
}

export interface IStoreFrontBrandDetails {
  _id: string;
  storeFrontBrandId: string;
  preScreening: IPreScreening;
  brandSetup: IBrandSetup;
  taxAndBankDetails: ITaxAndBankDetails;
  verification: IVerification;
  uniqueLink: string;
}

export interface IBrandApprovalLog {
  _id: string;
  storeFrontBrandApprovalId: string;
  storeFrontBrandId: string;
  approvalStatus: string;
  action: string;
  remarks: string;
  createdAt: string;
  updatedAt: string;
  createdBy: ICreatedBy;
  updatedBy: ICreatedBy;
  __v: number;
}

export interface IStoreFrontProfileApproval {
  _id: string;
  storeFrontBrandId: string;
  approvalStatus: string;
  brandApprovalLogs: Array<IBrandApprovalLog>;
  brandSetup: IBrandSetup;
  storeFrontBrandApprovalId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IStoreFrontProfileApprovalList {
  totalCount: number;
  totalPages: number;
  skip: number;
  limit: number;
  results: Array<IStoreFrontProfileApproval>;
}

export interface IStoreFrontProductApproval {
  _id: string;
  storeFrontProductId: string;
  storeFrontProductApprovalId: string;
  storeFrontBrandId: string;
  name: string;
  approvalStatus: string;
  createdAt: string;
  updatedAt: string;
  brandName: string;
}

export interface IStoreFrontProductApprovalList {
  productApprovals: Array<IStoreFrontProductApproval>;
  productApprovalsCount: number;
}

export interface IStoreFrontProfileFilter {
  search: string;
  approvalStatus: string;
}

export interface IBadge {
  _id: string;
  badgeName: string;
  badgeImage: IAssets;
  badgeId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ICategory {
  categoryId: string;
  subCategoryId: string;
}

export interface IExpirationDetails {
  expiryPeriod: number;
  unitOfTime: string;
}

export interface ITaxInfo {
  sgst?: number;
  cgst?: number;
  igst?: number;
}

export interface INutritionalInfo {
  nutritionName: string;
  nutritionValue: number;
  nutritionUOM: string;
}

export interface IProductInfo {
  sku: number;
  skuUOM: string;
  price: number;
}

export interface IStoreFrontProductDetails {
  _id: string;
  storeFrontProductId: string;
  storeFrontBrandId: string;
  name: string;
  description: string;
  ingredients: Array<string>;
  allergicInfo: string;
  nutritionalInfo: Array<INutritionalInfo>;
  storageCondition: string;
  hsnCode: string;
  taxInfo: ITaxInfo;
  foodType: string;
  productInfo: Array<IProductInfo>;
  storeFrontProductApprovalId?: string;
  assets: Array<IAssets>;
  crossSellProductId: Array<string>;
  expirationDetails: IExpirationDetails;
  category: Array<ICategory>;
  brandCategory: Array<ICategory>;
  createdBy: ICreatedBy;
  createdAt: string;
  updatedAt: string;
  updatedBy: ICreatedBy;
  approvalStatus: string;
  isExistingProduct: boolean;
  isDrafted: boolean;
  submittedAt: string;
  revenueSharingPercentage: string;
}

export interface IProductBase {
  storeFrontProductId?: string;
  storeFrontProductApprovalId?: string;
  storeFrontBrandId: string;
  name: string;
  description: string;
  ingredients: Array<string>;
  approvalStatus?: string;
  allergicInfo: string;
  nutritionalInfo: Array<INutritionalInfo>;
  storageCondition: string;
  hsnCode: string;
  taxInfo: ITaxInfo;
  foodType: string;
  productInfo: IProductInfo;
  assets: Array<IAssets>;
  crossSellProductId: Array<string>;
  expirationDetails: IExpirationDetails;
  category?: Array<ICategory>;
  brandCategory: Array<ICategory>;
  createdBy?: ICreatedBy;
  isDrafted: boolean;
  submittedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  revenueSharingPercentage?: string;
}

export interface IProducts extends IProductBase {
  isExistingProduct: boolean;
}

export interface IStoreFrontProductPayload extends IProductBase {
  isActive?: boolean;
  isExistingProduct?: boolean;
}

export interface IBrandCategory {
  _id?: string;
  storeFrontCategoryId: string;
  brandName?: string;
  name: string;
  brandId?: string;
  image?: IImageAssets;
  createdBy?: ICreatedBy;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
  categoryType?: string;
  categoryImageUrl?: string;
  imageUrl?: string;
}

export interface IAllBrandCategories {
  categories: Array<IBrandCategory>;
  categoriesCount: number;
}

export interface IBrandSubCategory {
  _id?: string;
  storeFrontSubCategoryId: string;
  storeFrontCategoryId?: string;
  name: string;
  createdBy?: ICreatedBy;
  createdAt?: string;
  updatedAt?: string;
  image?: IImageAssets;
  subCategoryImageUrl?: IFileUrl;
}

export interface IAllBrandSubCategories {
  subCategories: Array<IBrandSubCategory>;
  subCategoriesCount: number;
}

export interface IRelatedProduct {
  _id: string;
  storeFrontProductId: string;
  name: string;
}

export interface IRelatedProducts {
  products: Array<IRelatedProduct>;
  productsCount: number;
}

export interface IBrands {
  _id?: string;
  storeFrontBrandId: string;
  brandName: string;
}

export interface IStoreFrontBrandList {
  result: {
    brands: Array<IBrands>;
    brandsCount: number;
  };
}

export interface ICreateCategoryPayload {
  name: string;
  storeFrontBrandId?: string;
  image: IImageAssets;
}

export interface ICreateSubCategoryPayload {
  name: string;
  storeFrontCategoryId?: string;
  image?: IImageAssets;
}

export interface IUpdateCategorySequencePayload {
  storeFrontCategoryIds: Array<string>;
}

export interface IUpdateSubCategorySequencePayload {
  storeFrontSubCategoryIds: Array<string>;
}

// Brand Profile

interface FileUrl {
  url: string;
}

interface BrandAsset {
  s3FileName: string;
  originalFileName: string;
  contentType: string;
  fileType: string;
  fileUrl: FileUrl;
}

interface CertificateAndLabTestReportAsset extends BrandAsset { }

export interface BrandProfile {
  brandName: string;
  brandAssets: BrandAsset[];
  badges: string[];
  certificateAndLabTestReportAssets: CertificateAndLabTestReportAsset[];
}

export interface IBrandProfiles {
  _id: string;
  storeFrontBrandId: string;
  __v: number;
  brandProfile: BrandProfile;
  createdAt: string;
  isActive: boolean;
  isDeleted: boolean;
  updatedAt: string;
  updatedBy: string | null;
  phoneNumber: string;
}

interface IBrandProfileApiResponse {
  totalCount: number;
  totalPages: number;
  skip: number;
  limit: number;
  results: IBrandProfiles[];
}

export interface Products {
  storeFrontBrandId: string;
  storeFrontProductId: string;
  name: string;
  description: string;
  foodType: string;
  brandName: string;
  productInfo: IProductInfo[];
}
export interface IBrandProducts {
  products: Array<Products>;
}

export interface IFetchProductsQuery {
  skip: number,
  limit: number,
  storeFrontBrandId?: string,
  search?: string,
}

@Injectable()
export class BrandProfileService extends RestApiService {
  protected adminServiceBaseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  protected kitchenServiceBaseApiUrl = environment.config['KITCHEN_SERVICE_URL'];

  protected configServiceBaseApiUrl = environment.config['CONFIG_SERVICE_URL'];

  protected sellerPipelineApiUrl = environment.config['SELLER_PIPELINE_SERVICE_URL'];

  private servicePath = `${this.sellerPipelineApiUrl}`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  // Brand profile approval related API's
  async fetchStoreFrontBrandDetailsById(storeFrontBrandId: string): Promise<{ data: IStoreFrontBrandDetails; }> {
    const apiPath = `${this.servicePath}/storefront-portal/${storeFrontBrandId}`;
    return this.httpGet(apiPath);
  }

  async getStoreFrontBadge(): Promise<{ data: Array<IBadge>; }> {
    const apiPath = `${this.servicePath}/storefront-badge`;
    return this.httpGet(apiPath);
  }

  async fetchAllStoreFrontProfileApprovalList(queryParams: Partial<any>): Promise<{ data: IStoreFrontProfileApprovalList; }> {
    const searchParams = new URLSearchParams();
    const filter: IStoreFrontProfileFilter = {} as IStoreFrontProfileFilter;
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['approvalStatus'] && queryParams['approvalStatus'] !== 'all') {
        filter.approvalStatus = queryParams['approvalStatus'];
      } else {
        searchParams.delete('approvalStatus');
      }
      if ('search' in queryParams) {
        filter.search = queryParams['search'];
      } else {
        searchParams.delete('search');
      }
    }
    if (Object.keys(filter).length) {
      searchParams.set('filter', JSON.stringify(filter));
    }
    const apiPath = `${this.servicePath}/storefront-portal-approval?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchStoreFrontApprovalDetailsById(storeFrontBrandApprovalId: string)
    : Promise<{ data: { brandApproval: IStoreFrontProfileApproval; }; }> {
    const apiPath = `${this.servicePath}/storefront-portal-approval/${storeFrontBrandApprovalId}`;
    return this.httpGet(apiPath);
  }

  async updateBrandSetupProfileById(storeFrontBrandId: string, payload: { brandSetup: IBrandSetup; }): Promise<{ data: IStoreFrontBrandDetails; }> {
    const apiPath = `${this.servicePath}/storefront-portal/${storeFrontBrandId}/brand-setup`;
    return this.httpPut(apiPath, payload);
  }

  async updateStoreFrontApprovalDetails(storeFrontBrandApprovalId: string, payload: { brandSetup: IBrandSetup; }): Promise<{ data: IStoreFrontBrandDetails; }> {
    const apiPath = `${this.servicePath}/storefront-portal-approval/${storeFrontBrandApprovalId}`;
    return this.httpPatch(apiPath, payload);
  }

  async updateStoreFrontProfileApprovalStatus(storeFrontBrandApprovalId: string, payload: { approvalStatus: string, remarks?: string; })
    : Promise<{ data: IStoreFrontProfileApproval; }> {
    const apiPath = `${this.servicePath}/storefront-portal-approval/${storeFrontBrandApprovalId}/status`;
    return this.httpPatch(apiPath, payload);
  }

  // Brand product approval related API's
  async fetchAllStoreFrontProductApprovalList(queryParams: Partial<any>): Promise<{ data: IStoreFrontProductApprovalList; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['storeFrontBrandId']) {
        searchParams.set('storeFrontBrandId', String(queryParams['storeFrontBrandId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['approvalStatus'] && queryParams['approvalStatus'] !== 'ALL') {
        searchParams.set('approvalStatus', String(queryParams['approvalStatus']));
      } else {
        searchParams.delete('approvalStatus');
      }
      if ('isExistingProduct' in queryParams) {
        searchParams.set('isExistingProduct', String(queryParams['isExistingProduct']));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    const apiPath = `${this.servicePath}/storefront-product-approvals/list?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchStoreFrontProductApprovalLogs(storefrontProductApprovalId: string) {
    const apiPath = `${this.servicePath}/storefront-product-approvals/logs/${storefrontProductApprovalId}`;
    return this.httpGet(apiPath);
  }

  async getCompareStoreFrontProductById(productId: string): Promise<{ data: any; }> {
    const apiPath = `${this.servicePath}/storefront-product-approvals/comparison/products/${productId}`;
    return this.httpGet(apiPath);
  }

  async updateStoreFrontProductApprovalStatus(storeFrontProductId: string, payload: IApprovalPayload) {
    const apiPath = `${this.servicePath}/storefront-product-approvals/status/${storeFrontProductId}`;
    return this.httpPatch(apiPath, payload);
  }

  async fetchStoreFrontProductDetailsById(storeFrontProductId: string): Promise<{ data: IStoreFrontProductDetails; }> {
    const apiPath = `${this.servicePath}/storefront-product-approvals/details/${storeFrontProductId}`;
    return this.httpGet(apiPath);
  }

  async fetchAllStoreFrontBrandList(queryParams: Partial<any>): Promise<{ data: IStoreFrontBrandList; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    const apiPath = `${this.kitchenServiceBaseApiUrl}/storefront-brand/list/options?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async updateStoreFrontProductDetails(payload: IStoreFrontProductPayload, storeFrontProductId: string): Promise<{ data: IProducts; }> {
    const apiPath = `${this.servicePath}/storefront-product-approvals/${storeFrontProductId}`;
    return this.httpPut(apiPath, payload);
  }

  async fetchStoreFrontRelatedProductList(storefrontBrandId: string, queryParams: Partial<any>)
    : Promise<{ data: IRelatedProducts; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['excludeStoreFrontProductId']) {
        searchParams.set('excludeStoreFrontProductId', String(queryParams['excludeStoreFrontProductId']));
      }
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
    }
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/brands/${storefrontBrandId}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchAllBrandCategories(queryParams: Partial<any>): Promise<{ data: IAllBrandCategories; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['categoryType']) {
        searchParams.set('categoryType', String(queryParams['categoryType']));
      }
      if (queryParams['isActive']) {
        searchParams.set('isActive', String(queryParams['isActive']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
    }
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/category?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchCategoryDetailsById(storeFrontCategoryId: string): Promise<{ data: IBrandCategory; }> {
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/category/${storeFrontCategoryId}`;
    return this.httpGet(apiPath);
  }

  async fetchAllBrandCategoriesById(storefrontBrandId: string, queryParams: Partial<any>): Promise<{ data: IAllBrandCategories; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['categoryType']) {
        searchParams.set('categoryType', String(queryParams['categoryType']));
      }
      if (queryParams['isActive']) {
        searchParams.set('isActive', String(queryParams['isActive']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
    }
    const apiPath = `${this.kitchenServiceBaseApiUrl}/storefront-products/category/${storefrontBrandId}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchBrandSubCategoriesById(storeFrontCategoryId: string, queryParams: Partial<any>): Promise<{ data: IAllBrandSubCategories; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
    }
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/sub-category/${storeFrontCategoryId}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  // Category and Subcategory Api's
  async createStoreFrontBrandCategory(payload: ICreateCategoryPayload): Promise<{ data: IBrandCategory; }> {
    const apiPath = `${this.servicePath}/storefront-product-approvals/category`;
    return this.httpPost(apiPath, payload);
  }

  async createStoreFrontBrandSubCategory(payload: ICreateSubCategoryPayload): Promise<{ data: IBrandSubCategory; }> {
    const apiPath = `${this.servicePath}/storefront-product-approvals/sub-category`;
    return this.httpPost(apiPath, payload);
  }

  async createStoreFrontPlatformCategory(payload: ICreateCategoryPayload): Promise<{ data: IBrandCategory; }> {
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/category`;
    return this.httpPost(apiPath, payload);
  }

  async updateStoreFrontPlatformCategory(storefrontCategoryId: string, payload: ICreateCategoryPayload): Promise<{ data: IBrandCategory; }> {
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/category/${storefrontCategoryId}`;
    return this.httpPut(apiPath, payload);
  }

  async createStoreFrontPlatformSubCategory(payload: ICreateSubCategoryPayload): Promise<{ data: IBrandSubCategory; }> {
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/sub-category`;
    return this.httpPost(apiPath, payload);
  }

  async updateStoreFrontPlatformSubCategory(storefrontSubCategoryId: string, payload: ICreateSubCategoryPayload): Promise<{ data: IBrandSubCategory; }> {
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/sub-category/${storefrontSubCategoryId}`;
    return this.httpPatch(apiPath, payload);
  }

  // Category and Sub-Category sequence Api's
  async updateStoreFrontCategorySequence(queryParams: Partial<any>, payload: IUpdateCategorySequencePayload) {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['categoryType']) {
        searchParams.set('categoryType', String(queryParams['categoryType']));
      }
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['storeFrontBrandId']) {
        searchParams.set('storeFrontBrandId', String(queryParams['storeFrontBrandId']));
      }
    }
    const apiPath = `${this.configServiceBaseApiUrl}/common/configurations/modify/store-front/category/sequence?${searchParams.toString()}`;
    return this.httpPut(apiPath, payload);
  }

  async updateStoreFrontSubCategorySequence(queryParams: Partial<any>, payload: IUpdateSubCategorySequencePayload) {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['categoryType']) {
        searchParams.set('categoryType', String(queryParams['categoryType']));
      }
      if (queryParams['storeFrontCategoryId']) {
        searchParams.set('storeFrontCategoryId', String(queryParams['storeFrontCategoryId']));
      }
    }
    const apiPath = `${this.configServiceBaseApiUrl}/common/configurations/modify/store-front/sub-category/sequence?${searchParams.toString()}`;
    return this.httpPut(apiPath, payload);
  }

  async fetchStoreFrontCategorySequence(queryParams: Partial<any>): Promise<{ data: Array<IBrandCategory>; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['categoryType']) {
        searchParams.set('categoryType', String(queryParams['categoryType']));
      }
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['storeFrontBrandId']) {
        searchParams.set('storeFrontBrandId', String(queryParams['storeFrontBrandId']));
      }
    }
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/category/sequence/list?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchStoreFrontSubCategorySequence(queryParams: Partial<any>): Promise<{ data: Array<IBrandSubCategory>; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['categoryType']) {
        searchParams.set('categoryType', String(queryParams['categoryType']));
      }
      if (queryParams['storeFrontCategoryId']) {
        searchParams.set('storeFrontCategoryId', String(queryParams['storeFrontCategoryId']));
      }
    }
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/sub-category/sequence/list?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchAllBrandProfileList(queryParams: Partial<any>): Promise<{ data: IBrandProfileApiResponse; }> {
    const searchParams = new URLSearchParams();
    const filter: IStoreFrontProfileFilter = {} as IStoreFrontProfileFilter;
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      // if (queryParams['approvalStatus'] && queryParams['approvalStatus'] !== 'all') {
      //   filter.approvalStatus = queryParams['approvalStatus'];
      // } else {
      //   searchParams.delete('approvalStatus');
      // }
      if ('search' in queryParams) {
        filter.search = queryParams['search'];
      } else {
        searchParams.delete('search');
      }
    }
    if (Object.keys(filter).length) {
      searchParams.set('filter', JSON.stringify(filter));
    }
    const apiPath = `${this.kitchenServiceBaseApiUrl}/storefront-brand/common/profile?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchBrandProfileDetailsById(brandProfileId: string)
    : Promise<{ data: IBrandProfiles; }> {
    const apiPath = `${this.kitchenServiceBaseApiUrl}/storefront-brand/common/profile/${brandProfileId}`;
    return this.httpGet(apiPath);
  }

  async updateBrandProfileStatus(storeFrontBrandApprovalId: string, payload: { isActive: boolean; }): Promise<{ data: IBrandProfiles; }> {
    const apiPath = `${this.kitchenServiceBaseApiUrl}/storefront-brand/common/profile/${storeFrontBrandApprovalId}/toggle-active`;
    return this.httpPatch(apiPath, payload);
  }

  async fetchAllProducts(queryParams: IFetchProductsQuery): Promise<{ data: IBrandProducts; }> {
    const searchParams = new URLSearchParams();
    const trimmedQueryParams = removeEmptyValues(queryParams);
    Object.entries(trimmedQueryParams).forEach(([key, value]) => {
      searchParams.set(key, String(value));
    });
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchProductDetailsById(storeFrontProductId: string): Promise<{ data: Products; }> {
    const apiPath = `${this.kitchenServiceBaseApiUrl}/common/storefront-products/${storeFrontProductId}`;
    return this.httpGet(apiPath);
  }

  async updateStoreFrontDetailsByAdmin(storeFrontBrandApprovalId: string, payload: IBrandProfileUpdate): Promise<{ data: IStoreFrontBrandDetails; }> {
    const apiPath = `${this.kitchenServiceBaseApiUrl}/storefront-brand/common/profile/brands/${storeFrontBrandApprovalId}`;
    return this.httpPatch(apiPath, payload);
  }
}
