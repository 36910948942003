export enum Role {
  SUPER_MASTER_ADMIN = 'SUPER_MASTER_ADMIN',
  COOK_ADMIN = 'COOK_ADMIN',
  DE_ADMIN = 'DE_ADMIN',
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  ZONAL_ADMIN = 'ZONAL_ADMIN',
  CONTROL_TOWER = 'CONTROL_TOWER',
  VIEW_MASTER_ADMIN = 'VIEW_MASTER_ADMIN',
  BRAND_ADMIN = 'BRAND_ADMIN',
}
