import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabTypes } from '../enums/tab.types';
import { LayoutComponent } from './layout/layout.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { AuthGuard } from '../guards/auth.guard';
import { AbilityGuard } from '../guards/ability.guard';

const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  redirectTo: 'dashboard',
}, {
  path: 'dashboard',
  component: LayoutComponent,
  canActivate: [AuthGuard, AbilityGuard],
  children: [{
    path: 'cook-admin',
    data: {
      dashboardType: TabTypes.COOK_ADMIN,
    },
    loadChildren: () => import('./cook-admin/cook-admin.module').then((m) => m.CookAdminModule),
  }, {
    path: 'driver-admin',
    data: {
      dashboardType: TabTypes.DRIVER_ADMIN,
    },
    loadChildren: () => import('./driver-admin/driver-admin.module').then((m) => m.DriverAdminModule),
  }, {
    path: 'customer-admin',
    data: {
      dashboardType: TabTypes.CUSTOMER_ADMIN,
    },
    loadChildren: () => import('./customer-admin/customer-admin.module').then((m) => m.CustomerAdminModule),
  }, {
    path: 'brand-admin',
    data: {
      dashboardType: TabTypes.BRAND_ADMIN,
    },
    loadChildren: () => import('./brand-admin/brand-admin.module').then((m) => m.BrandAdminModule),
  },
  ],
}, {
  path: 'user-management',
  loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule),
  canActivate: [AuthGuard, AbilityGuard],
},
{
  path: 'login',
  pathMatch: 'full',
  redirectTo: 'dashboard',
},
{
  path: '**',
  component: NotFoundPageComponent,
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
