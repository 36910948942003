export enum DeepLinkRedirectEnums {
  // TRACK_ORDER = 'track-order',
  // PRODUCT_PAGE = 'product',
  // ORDER_HISTORY_PAGE = 'order-history',
  // ORDER_REVIEW = 'order-review',
  // DELIVERY_REVIEW = 'delivery-review',
  // CHAT_PAGE = 'cookr-support-chat',
  // ACCOUNT__PAGE = 'account',
  // MY_CART_PAGE = 'my-cart',
  // ITEM_SEARCH = 'item-search',
  // FAVORITE_KITCHEN = 'favorite-kitchen',
  // REFUND_POLICY = 'refund-policy',
  // ORDER_INPROGRESS = 'order-inprogress',
  // PURE_VEG_FILTER = 'pure-veg-filter',
  // TOP_RATED_FILTER = 'top-veg-filter',
  // WEB_URL = 'web-url',
  // WEB_URL_IN_APP = 'web-url-inapp',
  SPECIAL_CATEGORY = 'special-category',
  KITCHEN_PAGE = 'kitchen',
  COUPON_DESCRIPTION_MODAL = 'coupon-description',
}

export enum ProductDeepLinkRedirectEnums {
  SELLER_PRODUCT_CATEGORY = 'seller-product-category',
  COUPON_DESCRIPTION_MODAL = 'coupon-description',
  // SELLER_PRODUCT_SUB_CATEGORY = 'seller-product-sub-category',
  // SELLER_BRAND = 'seller-brand',
}

export enum BrandDeepLinkRedirectEnums {
  BRAND_CATEGORY = 'brand-category',
  PRODUCT = 'product',
  BRAND = 'brand',
  // COUPONS = 'coupons'
}

export enum BannerSellerTypes {
  SELLER_PRODUCTS = 'sellerProducts',
  FOOD_ITEMS = 'foodItems',
  SUBSCRIPTION = 'subscription',
  BRAND = 'storeFrontSeller'
}

export function getDeepLinkRedirectionTypeFromEnum(enumValue: string): string {
  switch (enumValue) {
    case 'special-category':
      return 'Special Category';
    case 'kitchen':
      return 'Kitchen';
    case 'coupon-description':
      return 'Coupon';
    case 'seller-product-category':
      return 'Product Category';
    case 'seller-product-sub-category':
      return 'Product Sub Category';
    case 'seller-brand':
      return 'Product Brand';
    case 'brand-category':
      return 'Platform Category';
    case 'product':
      return 'Product';
    case 'brand':
      return 'Brand';
    case 'coupons':
      return 'Coupons';
    // ... add cases for other enum values if needed
    default:
      return enumValue;
  }
}
