export enum AppAbilityKey {

  // MAIN_TAB
  COOK_ADMIN = 'cookAdmin',
  DE_ADMIN = 'deAdmin',
  CUSTOMER_ADMIN = 'customerAdmin',
  BRAND_ADMIN = 'brandAdmin',

  // COOK_ADMIN_TAB
  COOK_ADMIN_ORDER_DASHBOARD = 'cookAdminOrderDashboard',
  COOK_ADMIN_SCHEDULE_DASHBOARD = 'cookAdminScheduleDashboard',
  COOK_ADMIN_VIEW_CART_ITEMS = 'cookAdminViewCartItems',
  COOK_ADMIN_VIEW_COOK = 'cookAdminViewCook',
  COOK_ADMIN_NEW_COOK_ONBOARD = 'cookAdminNewCookOnboard',

  // NEW_COOK_ONBOARD
  COOK_ADMIN_COOK_PERSONAL_INFORMATION = 'cookAdminPersonalInformations',
  COOK_ADMIN_COOK_BANK_DETAILS = 'cookAdminBankDetails',
  COOK_ADMIN_COOK_DOCUMENT_DETAILS = 'cookAdminDocumentDetails',
  COOK_ADMIN_COOK_TERMS_AND_CONDITIONS = 'cookAdminTermsAndConditions',
  COOK_ADMIN_COOK_KITCHEN_VIEWS = 'cookAdminKitchenView',
  COOK_ADMIN_VERIFY_COOK_BANK_DETAILS = 'cookAdminVerifyCookBankDetails',

  // KITCHEN-ONBOARDING
  COOK_ADMIN_KITCHEN_ONBOARDING = 'cookAdminKitchenOnboarding',

  // SELLER_PROFILE
  COOK_ADMIN_SELLER_PROFILE = 'cookAdminSellerProfile',
  COOK_ADMIN_ADD_MENU = 'cookAdminAddMenu',
  COOK_ADMIN_SCHEDULE_MENU = 'cookAdminScheduleMenu',
  COOK_ADMIN_KITCHEN_ORDER_HISTORY = 'cookAdminKitchenOrderHistory',
  COOK_ADMIN_KITCHEN_INSPECTION = 'cookAdminKitchenInspection',
  COOK_ADMIN_MENU_APPROVAL = 'cookAdminMenuApproval',
  COOK_ADMIN_PRODUCT_APPROVAL = 'cookAdminProductApproval',
  COOK_ADMIN_PRODUCT_CATEGORY = 'cookAdminProductCategory',
  COOK_ADMIN_PRODUCT_CATEGORY_SEQUENCE = 'cookAdminProductCategorySequence',
  COOK_ADMIN_PRODUCT_SUB_CATEGORY_SEQUENCE = 'cookAdminProductSubCategorySequence',
  COOK_ADMIN_SELLER_PROFILE_APPROVAL = 'cookAdminSellerProfileApproval',
  COOK_ADMIN_FOOD_SELLER_PROFILE = 'cookAdminFoodProfile',
  COOK_ADMIN_PRODUCT_SELLER_PROFILE = 'cookAdminProductProfile',
  COOK_ADMIN_ADD_PRODUCT = 'cookAdminAddProduct',
  COOK_ADMIN_PRODUCT_INVENTORY = 'cookAdminProductInventory',

  // EARNINGS
  COOK_ADMIN_COOK_EARNINGS = 'cookAdminCookEarnings',
  COOK_ADMIN_PRODUCT_EARNINGS = 'cookAdminProductEarnings',

  // KITCHEN_PAYOUTS
  COOK_ADMIN_COOK_PAYOUTS = 'cookAdminCookPayouts',
  COOK_ADMIN_GENERATE_COOK_PAYOUTS = 'cookAdminGenerateCookPayouts',
  COOK_ADMIN_VIEW_COOK_PAYOUTS = 'cookAdminViewCookPayouts',
  COOK_ADMIN_OTHER_DISPUTES = 'cookAdminOtherDisputes',
  COOK_ADMIN_UPDATE_PAYOUT_STATUS = 'cookAdminUpdatePayoutStatus',
  COOK_ADMIN_COOK_PAYOUTS_APPROVAL = 'cookAdminCookPayoutsApproval',
  COOK_ADMIN_COOK_PAYOUTS_INITIATE = 'cookAdminCookPayoutsInitiate',
  COOK_ADMIN_COOK_PAYOUTS_INITIATE_BEFORE_TWO_WEEKS = 'cookAdminCookPayoutsInitiateBeforeTwoWeeks',

  // PRODUCT_PAYOUTS
  COOK_ADMIN_PRODUCT_PAYOUTS = 'cookAdminProductPayouts',
  COOK_ADMIN_GENERATE_PRODUCT_PAYOUTS = 'cookAdminGenerateProductPayouts',
  COOK_ADMIN_VIEW_PRODUCT_PAYOUTS = 'cookAdminViewProductPayouts',
  COOK_ADMIN_UPDATE_PRODUCT_PAYOUT_STATUS = 'cookAdminUpdateProductPayoutStatus',
  COOK_ADMIN_PRODUCT_PAYOUTS_APPROVAL = 'cookAdminProductPayoutsApproval',
  COOK_ADMIN_PRODUCT_PAYOUTS_INITIATE = 'cookAdminProductPayoutsInitiate',
  COOK_ADMIN_PRODUCT_PAYOUTS_INITIATE_BEFORE_TWO_WEEKS = 'cookAdminProductPayoutsInitiateBeforeTwoWeeks',

  // SUBSCRIPTION_PAYOUTS
  COOK_ADMIN_SUBSCRIPTION_PAYOUTS = 'cookAdminSubscriptionPayouts',
  COOK_ADMIN_GENERATE_SUBSCRIPTION_PAYOUTS = 'cookAdminGenerateSubscriptionPayouts',
  COOK_ADMIN_VIEW_SUBSCRIPTION_PAYOUTS = 'cookAdminViewSubscriptionPayouts',
  COOK_ADMIN_UPDATE_SUBSCRIPTION_PAYOUT_STATUS = 'cookAdminUpdateSubscriptionPayoutStatus',
  COOK_ADMIN_SUBSCRIPTION_PAYOUTS_APPROVAL = 'cookAdminSubscriptionPayoutsApproval',
  COOK_ADMIN_SUBSCRIPTION_PAYOUTS_INITIATE = 'cookAdminSubscriptionPayoutsInitiate',
  COOK_ADMIN_SUBSCRIPTION_PAYOUTS_INITIATE_BEFORE_TWO_WEEKS = 'cookAdminSubscriptionPayoutsInitiateBeforeTwoWeeks',

  // ORDER_HISTORY
  COOK_ADMIN_ORDER_HISTORY = 'cookAdminOrderHistory',
  COOK_ADMIN_TODAYS_ORDERS = 'cookAdminTodaysOrders',
  COOK_ADMIN_VIEW_ALL_ORDERS = 'cookAdminViewAllOrders',
  COOK_ADMIN_SUBSCRIPTION_ORDERS = 'cookAdminSubscriptionOrders',
  COOK_ADMIN_KITCHEN_MORE_THAN_5_ORDERS = 'cookAdminKitchenMoreThan5Orders',

  COOK_ADMIN_ORDER_HISTORY_ADD_NOTES = 'cookAdminOrderHistoryAddNotes',
  COOK_ADMIN_ORDER_HISTORY_EDIT_ORDER_STATUS = 'cookAdminOrderHistoryEditOrderStatus',
  COOK_ADMIN_ORDER_HISTORY_EDIT_DELIVERED_ORDER_STATUS = 'cookAdminOrderHistoryEditDeliveredOrderStatus',

  // ORDER_PAYOUTS_DISPUTES
  COOK_ADMIN_ORDER_DISPUTES = 'cookAdminOrderDisputes',
  COOK_ADMIN_VIEW_ORDER_DISPUTES = 'cookAdminViewOrderDisputes',
  COOK_ADMIN_ADD_ORDER_DISPUTES = 'cookAdminAddOrderDisputes',
  COOK_ADMIN_VIEW_ORDER_DETAILS = 'cookAdminViewOrderDetails',

  COOK_ADMIN_COOK_LEAD_INTEREST = 'cookAdminCookLeadInterest',

  // ZONE_LIST
  COOK_ADMIN_ZONE_LIST = 'cookAdminZoneList',
  COOK_ADMIN_ADD_PARENT_ZONE = 'cookAdminAddParentZone',
  COOK_ADMIN_ADD_SUB_ZONE = 'cookAdminAddSubZone',
  COOK_ADMIN_PARENT_ZONE_LIST = 'cookAdminParentZoneList',
  COOK_ADMIN_SUB_ZONE_LIST = 'cookAdminSubZoneList',
  COOK_ADMIN_SUB_ZONE_LISTING_OF_KITCHENS = 'cookAdminSubZoneListingOfKitchens',

  COOK_ADMIN_COOK_REVIEWS = 'cookAdminCookReviews',
  COOK_ADMIN_CUISINE_MANAGEMENT = 'cookAdminCuisineManagement',
  COOK_ADMIN_ADD_CUISINE = 'cookAddCuisine',
  COOK_ADMIN_CUISINE_LIST = 'cookAdminCuisineList',
  COOK_ADMIN_BANNER_MANAGEMENT = 'cookAdminCookBannerManagement',

  COOK_ADMIN_BROADCAST_MESSAGE = 'cookAdminBroadcastMessage',
  COOK_ADMIN_SATELLITE_STORE_CONFIGURATION = 'cookAdminSatelliteStoreConfiguration',
  COOK_ADMIN_PRE_BOOKING_CONFIGURATION = 'cookAdminPrebookingConfiguration',

  // COUPON_MANAGEMENT_TAB
  COOK_ADMIN_COUPON_MANAGEMENT = 'cookAdminCouponManagement',
  COOK_ADMIN_COUPONS_MANAGEMENT_LIST = 'cookAdminCouponsManagementList',
  COOK_ADMIN_PRODUCT_COUPONS_MANAGEMENT_LIST = 'cookAdminProductCouponsManagementList',
  COOK_ADMIN_OFFERS_MANAGEMENT_LIST = 'cookAdminOffersManagementList',
  COOK_ADMIN_PRODUCT_OFFERS_MANAGEMENT_LIST = 'cookAdminProductOffersManagementList',
  COOK_ADMIN_COUPON_LOGS_LIST = 'cookAdminCouponLogsList',
  COOK_ADMIN_COUPON_VALID_TILL = 'cookAdminCouponValidTill',

  // DE_ADMIN_TAB
  DE_ADMIN_DASHBOARD = 'deAdminDashboard',
  DE_ADMIN_VIEW_DE = 'deAdminViewDE',

  // NEW_DE_TAB
  DE_ADMIN_NEW_DE_ONBOARD = 'deAdminNewDEOnboard',
  DE_ADMIN_PERSONAL_INFORMATION = 'deAdminPersonalInformation',
  DE_ADMIN_DOCUMENT_DETAILS = 'deAdminDocumentDetails',
  DE_ADMIN_BANK_DETAILS = 'deAdminBankDetails',
  DE_ADMIN_ASSET_DETAILS = 'deAdminAssetDetails',
  DE_ADMIN_TERMS_AND_CONDITIONS = 'deAdminTermsAndConditions',

  DE_ADMIN_RIDER_REVIEWS = 'deAdminRiderReviews',
  DE_ADMIN_KM_LOGS = 'deAdminKmLogs',
  DE_ADMIN_RIDER_STATUS_LOGS = 'deAdminRiderStatusLogs',
  DE_ADMIN_RIDER_LOCATION_TRACKING = 'deAdminRiderLocationTracking',

  // DE-ORDER_HISTORY
  DE_ADMIN_DELIVERY_STATUS = 'deAdminDeliveryStatus',
  DE_ADMIN_TODAYS_ORDERS = 'deAdminTodaysOrders',
  DE_ADMIN_VIEW_ALL_ORDERS = 'deAdminViewAllOrders',
  DE_ADMIN_SUBSCRIPTION_ORDERS = 'deAdminSubscriptionOrders',
  DE_ADMIN_ORDER_HISTORY_ASSIGN_RIDER = 'deAdminOrderHistoryAssignRider',
  DE_ADMIN_ORDER_HISTORY_ADD_NOTES = 'deAdminOrderHistoryAddNotes',
  DE_ADMIN_ORDER_HISTORY_EDIT_ORDER_STATUS = 'deAdminOrderHistoryEditOrderStatus',
  DE_ADMIN_ORDER_HISTORY_EDIT_DELIVERED_ORDER_STATUS = 'deAdminOrderHistoryEditDeliveredOrderStatus',

  // ORDER_PAYOUTS_DISPUTES
  DE_ADMIN_ORDER_DISPUTES = 'deAdminOrderDisputes',
  DE_ADMIN_VIEW_ORDER_DISPUTES = 'deAdminViewOrderDisputes',
  DE_ADMIN_ADD_ORDER_DISPUTES = 'deAdminAddOrderDisputes',
  DE_ADMIN_VIEW_ORDER_DETAILS = 'deAdminViewOrderDetails',

  // DE_PAYOUT
  DE_ADMIN_PAYOUTS_MASTERCARD = 'deAdminPayoutsMastercard',
  DE_ADMIN_VIEW_MASTER_CARD = 'deAdminViewMasterCard',
  DE_ADMIN_ADD_MASTER_CARD = 'deAdminAddMasterCard',

  DE_ADMIN_RIDER_LEAD = 'deAdminRiderLead',
  DE_ADMIN_RIDER_ERROR_LOGS = 'deAdminRiderErrorLogs',
  DE_ADMIN_BANNER_MANAGEMENT = 'deAdminRiderBannerManagement',

  // CUSTOMER_ADMIN_TAB
  CUSTOMER_ADMIN_BANNER_MANAGEMENT = 'customerAdminBannerManagement',
  CUSTOMER_ADMIN_BRAND_BANNER_MANAGEMENT = 'customerAdminBannerManagement',
  CUSTOMER_ADMIN_SCROLLABLE_BANNER_MANAGEMENT = 'customerAdminScrollableBannerManagement',
  CUSTOMER_ADMIN_THEME_BRAND_BANNER_MANAGEMENT = 'customerAdminThemeBrandBannerManagement',
  CUSTOMER_ADMIN_THEME_BANNER_MANAGEMENT = 'customerAdminThemeBannerManagement',
  CUSTOMER_ADMIN_ANDRIOD_CONFIGURATION_MANAGEMENT = 'customerAdminAndriodConfigurationManagement',
  CUSTOMER_ADMIN_IOS_CONFIGURATION_MANAGEMENT = 'customerAdminIOSConfigurationManagement',

  // CUSTOMER APP GENERAL CONFIG
  CUSTOMER_ADMIN_GENERAL_CONFIGURATION = 'customerAdminAccountConfiguration',
  CUSTOMER_ADMIN_MANAGE_ACCOUNT_CONFIGURATION = 'customerAdminManageAccount',

  // GENERAL_CONFIG
  CUSTOMER_ADMIN_GENERAL_CONFIGURATION_MANAGEMENT = 'customerAdminGeneralConfigurationManagement',
  CUSTOMER_ADMIN_GET_IT_IMMEDIATELY_CONFIGURATION = 'customerAdminGetItImmediatelyConfigurationManagement',
  CUSTOMER_ADMIN_RUSHING_KITCHEN_CONFIGURATION = 'customerAdminRushingKitchenConfigurationManagement',

  // CUSTOMER_SECTION_CARD_MANAGEMENT_TAB
  CUSTOMER_ADMIN_SECTION_CARD_MANAGEMENT = 'customerAdminSectionCardManagement',
  CUSTOMER_ADMIN_VIEW_SECTION_CARDS = 'customerAdminViewSectionCards',
  CUSTOMER_ADMIN_SECTION_CARD_SEQUENCE = 'customerAdminSectionCardSequence',
  CUSTOMER_ADMIN_PRODUCT_SECTION_CARDS = 'customerAdminProductSectionCards',
  CUSTOMER_ADMIN_PRODUCT_SECTION_CARDS_SEQUENCE = 'customerAdminProductSectionCardSequence',

  // TIME_SLOT
  CUSTOMER_ADMIN_TIME_SLOT_MANAGEMENT = 'customerAdminTimeSlotManagement',
  CUSTOMER_ADMIN_DELIVERY_TIME = 'customerAdminDeliveryTime',
  CUSTOMER_ADMIN_COOK_START_ORDER_TIME = 'customerAdminCookStartOrderTime',

  CUSTOMER_ADMIN_CALENDAR_EVENTS_MANAGEMENT = 'customerAdminCalendarEventsManagement',
  CUSTOMER_ADMIN_REFERRAL_CONFIGURATION_MANAGEMENT = 'customerAdminReferralConfigurationManagement',
  CUSTOMER_ADMIN_REFERRAL_LOGS = 'customerAdminReferralLogs',
  CUSTOMER_ADMIN_PUSH_NOTIFICATION = 'customerAdminPushNotification',
  CUSTOMER_ADMIN_DELAY_NOTIFICATION = 'customerAdminDelayNotification',

  // ORDER_HISTORY
  CUSTOMER_ADMIN_PURCHASE_ORDER_HISTORY = 'customerAdminPurchaseOrderHistory',
  CUSTOMER_ADMIN_TODAYS_ORDERS = 'customerAdminTodaysOrders',
  CUSTOMER_ADMIN_VIEW_ALL_ORDERS = 'customerAdminViewAllOrders',
  CUSTOMER_ADMIN_SUBSCRIPTION_ORDERS = 'customerAdminSubscriptionOrders',
  CUSTOMER_ADMIN_ORDER_HISTORY_ADD_NOTES = 'customerAdminOrderHistoryAddNotes',
  CUSTOMER_ADMIN_ORDER_HISTORY_INITIATE_REFUND = 'customerAdminOrderHistoryInitiateRefund',
  CUSTOMER_ADMIN_ORDER_HISTORY_EDIT_ORDER_STATUS = 'customerAdminOrderHistoryEditOrderStatus',
  CUSTOMER_ADMIN_ORDER_HISTORY_EDIT_DELIVERED_ORDER_STATUS = 'customerAdminOrderHistoryEditDeliveredOrderStatus',
  CUSTOMER_ADMIN_ORDER_HISTORY_INITIATE_COUPON = 'customerAdminOrderHistoryInitiateCoupon',
  CUSTOMER_ADMIN_ORDER_ACTIVITY_LOGS = 'customerAdminOrderActivityLogs',

  // ORDER_PAYOUTS_DISPUTES
  CUSTOMER_ADMIN_ORDER_DISPUTES = 'customerAdminOrderDisputes',
  CUSTOMER_ADMIN_VIEW_ORDER_DISPUTES = 'customerAdminViewOrderDisputes',
  CUSTOMER_ADMIN_ADD_ORDER_DISPUTES = 'customerAdminAddOrderDisputes',
  CUSTOMER_ADMIN_VIEW_ORDER_DETAILS = 'customerAdminViewOrderDetails',

  // PACKAGING_CHARGES_CONFIGURATION
  // CUSTOMER_ADMIN_PACKAGING_CHARGES_CONFIGURATION = 'customerAdminPackagingChargesConfiguration',
  // CUSTOMER_ADMIN_PACKAGING_CHARGES = 'customerAdminPackagingCharges',

  // PLACE_ORDER
  CUSTOMER_ADMIN_PLACE_ORDER = 'customerAdminPlaceOrder',
  CUSTOMER_ADMIN_CREATE_ORDER = 'customerAdminCreateOrder',
  CUSTOMER_ADMIN_CUSTOMER_ORDER_HISTORY = 'customerAdminOrderHistory',

  // CUSTOMER-APP-CONFIGURATION
  CUSTOMER_ADMIN_MAP_API_CONFIGURATION = 'customerAdminMapAPIConfiguration',
  CUSTOMER_ADMIN_SEARCH_VENDOR_CONFIGURATION = 'customerAdminSearchVendorConfiguration',
  CUSTOMER_ADMIN_GREETING_MSG_CONFIGURATION = 'customerAdminGreetingMessageConfiguration',
  CUSTOMER_ADMIN_FEATURE_LIST_CONFIGURATION = 'customerAdminFeatureListConfiguration',
  CUSTOMER_ADMIN_DELIVERY_PREFERENCE_CONFIGURATION = 'customerAdminDeliveryPreferenceConfiguration',

  CUSTOMER_ADMIN_PLATFORM_FEE = 'customerAdminPlatformFeeConfiguration',
  CUSTOMER_ADMIN_PACKAGING_CHARGES = 'customerAdminPackagingCharges',
  CUSTOMER_ADMIN_SMALL_ORDER_FEE_CONFIG = 'customerAdminSmallOrderFeeConfiguration',
  CUSTOMER_ADMIN_DELIVERY_CHARGES = 'customerAdminDeliveryCharges',
  CUSTOMER_ADMIN_DELIVERY_CHARGE_SUGGESTION = 'customerAdminDeliveryChargeSuggestion',
  CUSTOMER_ADMIN_CUSTOMER_MANAGEMENT = 'customerAdminCustomerManagement',
  CUSTOMER_ADMIN_FOOD_COD_CONFIGURATION = 'customerAdminFoodCODConfiguration',
  CUSTOMER_ADMIN_PRODUCT_COD_CONFIGURATION = 'customerAdminProductCODConfiguration',

  CUSTOMER_ADMIN_CONTROL_TOWER_CONFIGURATION = 'customerAdminControlTowerConfiguration',
  CUSTOMER_ADMIN_TAX_CONFIGURATION = 'customerAdminTaxConfiguration',
  CUSTOMER_ADMIN_CANCELLATION_REASON_CONFIG = 'customerAdminCancellationReasonConfiguration',

  // USER_MANAGEMANT
  USER_MANAGEMENT_ALL_USERS = 'userManagementAllUsers',

  // SPECIAL_CATEGORY
  CUSTOMER_ADMIN_SPECIAL_CATEGORY_CONFIGURATION = 'customerAdminSpecialCategoryConfiguration',
  CUSTOMER_ADMIN_SPECIAL_CATEGORY_SEQUENCING = 'customerAdminSpecialCategorySequencing',

  // TAG_MASTER
  CUSTOMER_ADMIN_TAG_CONFIGURATION = 'customerAdminTagConfiguration',

  // PRODUCT_CONFIGURATION
  CUSTOMER_ADMIN_PRODUCT_CONFIGURATION = 'customerAdminProductConfiguration',

  // SUBSCRIPTION_DETAILS
  CUSTOMER_ADMIN_SUBSCRIPTION_DETAILS = 'customerAdminSubscriptionDetails',
  CUSTOMER_ADMIN_SUBSCRIPTION_EXTEND_BUTTON = 'customerAdminSubscriptionExtendButton',

  // SUBSCRIPTION_MENU_MASTER
  CUSTOMER_ADMIN_SUBSCRIPTION_MENU_MASTER = 'customerAdminSubscriptionMenuMaster',

  // REVIEWS_TRACKING
  CUSTOMER_ADMIN_REVIEWS_TRACKING = 'customerAdminReviewsTracking',

  // PARTY_ORDER_DETAILS
  CUSTOMER_ADMIN_PARTY_ORDER_DETAILS = 'customerAdminPartyOrderDetails',

  // BRAND_PROFILE
  BRAND_ADMIN_BRAND_PROFILE = 'brandAdminBrandProfile',
  BRAND_ADMIN_PROFILE_APPROVAL = 'brandAdminProfileApproval',
  BRAND_ADMIN_PRODUCT_APPROVAL = 'brandAdminProductApproval',
  BRAND_ADMIN_PRODUCT_CATEGORY = 'brandAdminProductCategory',
  BRAND_ADMIN_PRODUCT_SUB_CATEGORY = 'brandAdminProductSubCategory',
  BRAND_ADMIN_PRODUCT_CATEGORY_SEQUENCE = 'brandAdminProductCategorySequence',
  BRAND_ADMIN_PRODUCT_SUB_CATEGORY_SEQUENCE = 'brandAdminProductSubCategorySequence',
  BRAND_ADMIN_BANNER_MANAGEMENT = 'brandAdminBannerManagement',
  BRAND_ADMIN_DARK_STORE_CONFIGURATION = 'brandAdminDarkStoreConfiguration',
  BRAND_ADMIN_VIEW_BRAND = 'brandAdminViewBrand',
  BRAND_ADMIN_COUPON_MANAGEMENT = 'brandAdminCouponManagement',
  BRAND_ADMIN_COUPONS_MANAGEMENT_LIST = 'brandAdminCouponsManagementList',
  BRAND_ADMIN_OFFERS_MANAGEMENT_LIST = 'brandAdminOffersManagementList',
}
