export function removeEmptyValues(payload: any): any {
  if (Array.isArray(payload)) {
    return payload
      .map(removeEmptyValues)
      .filter((item) => {
        if (Array.isArray(item) && item.length === 0) return false; // Remove empty arrays
        if (typeof item === 'object' && item !== null && Object.keys(item).length === 0) return false; // Remove empty objects
        return item !== null && item !== undefined && item !== ''; // Keep non-empty values
      });
  } if (typeof payload === 'object' && payload !== null) {
    return Object.fromEntries(
      Object.entries(payload)
        .map(([key, value]) => [key, removeEmptyValues(value)])
        .filter(([, value]) => {
          if (value === 0) return true; // Keep if value is zero
          if (typeof value === 'boolean') return true;
          if (Array.isArray(value) && value.length === 0) return false; // Remove if empty array
          if (typeof value === 'string' && value.length === 0) return false; // Remove if empty string
          if (value === undefined || value === null) return false;
          if (typeof value === 'object' && Object.keys(value).length === 0) return false; // Remove if empty object
          return value !== undefined && value !== null; // Remove undefined, null, or false
        }),
    );
  }
  return payload;
}
