import { Injectable } from '@angular/core';
import { IAdminUser, UserApiService } from './api/user-api.service';
import { StorageService } from './storage.service';
import { AzureAdConfigService } from './azure-ad-config.service';
import { StorageKey } from '../enums/storage-key.enum';
import { AppAbility, defineAbilities } from './appAbility';
import { AppAbilityKey } from '../enums/ability-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class InitialService {
  public userInfo!: IAdminUser;

  constructor(
    private userApiService: UserApiService,
    private storageService: StorageService,
    private azureAdConfigService: AzureAdConfigService,
    private appAbility: AppAbility,
  ) { }

  async initialize() {
    try {
      if (this.azureAdConfigService.isAuthenticated) {
        const userGraph = await this.azureAdConfigService.getAzureUserProfile();
        if (userGraph?.id) {
          this.userInfo = (await this.userApiService.getAdminUserById(userGraph.id)).data;
          if (!this.userInfo || !this.userInfo.isActive) {
            // eslint-disable-next-line no-alert
            window.alert(
              'Please contact the admin or the direct manager for the access. To locate your direct manager, check your Teams Organisation!',
            );
            this.azureAdConfigService.logout();
          }
          this.storageService.set(StorageKey.USER_ABILITIES, this.userInfo.abilities);
          this.appAbility = this.fetchAbilities();
          if (!this.appAbility.can('view', AppAbilityKey.COOK_ADMIN) && !this.appAbility.can('view', AppAbilityKey.DE_ADMIN) && !this.appAbility.can('view', AppAbilityKey.CUSTOMER_ADMIN) && !this.appAbility.can('view', AppAbilityKey.BRAND_ADMIN)) {
            // eslint-disable-next-line no-alert
            window.alert(
              'Please contact the admin or the direct manager for the access. To locate your direct manager, check your Teams Organisation!',
            );
            this.azureAdConfigService.logout();
          }
        } else {
          this.azureAdConfigService.logout();
        }
      }
    } catch (error: any) {
      // eslint-disable-next-line no-alert
      if (error && error?.status === 406 && error?.error?.errorCode) {
        console.error(error);
      } else {
        window.alert(
          'Please contact the admin or the direct manager for the access. To locate your direct manager, check your Teams Organisation!',
        );
        this.azureAdConfigService.logout();
      }
    }
  }

  public isUserAuthorized() {
    const ability = this.storageService.get(StorageKey.USER_ABILITIES);
    if (ability) return defineAbilities(ability);
    return undefined;
  }

  public fetchAbilities() {
    const ability = this.storageService.get(StorageKey.USER_ABILITIES);
    return defineAbilities(ability);
  }

  public async getElgibleParentZones(): Promise<string[] | string> {
    const userZones = this.userInfo.attributes.filter((attr) => attr.attributeName === 'zoneInfo').map((zone) => zone.attributeValue as Array<string>);
    const eligibleParentZones: string[] = [];

    if (userZones[0].includes('all')) {
      return 'All';
    }

    if (userZones[0]) {
      const parentZonesFromZoneInfo: Array<string> = userZones[0];
      eligibleParentZones.push(...parentZonesFromZoneInfo);
    }
    return eligibleParentZones;
  }

  public fetchUserRole() {
    const userRoles = this.userInfo.attributes.filter((attr) => attr.attributeName === 'roles').map((roles) => roles.attributeValue as string);
    return userRoles[0];
  }
}
