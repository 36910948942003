import { FoodType } from './view-cook.constants';

export interface MenuCategoriesType {
  id: number;
  name: string;
  value: string;
}

export const MENU_CATEGORIES: Array<MenuCategoriesType> = [{
  id: 1,
  name: 'Breakfast',
  value: 'breakfast',
}, {
  id: 2,
  name: 'Lunch',
  value: 'lunch',
}, {
  id: 3,
  name: 'Snacks',
  value: 'snacks',
}, {
  id: 4,
  name: 'Dinner',
  value: 'dinner',
}];
export interface PreferredType {
  id: number;
  name: string;
  value: string;
}

export const VENDOR_NAME = [{
  id: 1,
  label: 'Porter',
  value: 'PORTER',
},
{
  id: 2,
  label: 'Dunzo',
  value: 'DUNZO',
},
{
  id: 3,
  label: 'Shadow Fax',
  value: 'SHADOW-FAX',
},
{
  id: 4,
  label: 'Adloggs',
  value: 'ADLOGGS',
},
{
  id: 5,
  label: 'Zomato-Xtreme',
  value: 'ZOMATOXTREME',
},
{
  id: 6,
  label: 'Qwqer',
  value: 'QWQER',
},
];

export const selectedCategoryToDisabledCategoryMap: {
  [foodType: string]: FoodType[]
} = {
  [FoodType.PURE_VEG]: [FoodType.VEG, FoodType.VEGAN, FoodType.NON_VEG],
  [FoodType.VEG]: [FoodType.PURE_VEG, FoodType.VEGAN],
  [FoodType.VEGAN]: [FoodType.VEG, FoodType.PURE_VEG, FoodType.NON_VEG],
  [FoodType.NON_VEG]: [FoodType.PURE_VEG, FoodType.VEGAN],
};
